import React, { useRef, useState, useEffect } from 'react';
import { useSlider } from 'react-use';
import { formatCurrency } from '@strikelabs/pax';
import PropTypes from 'prop-types';
import { Flex } from '@strikelabs/luna';

import { Track, TrackButton, TrackContainer, Thumb } from './style';

const Slider = ({
  increments,
  numberOnThumb,
  setValueCallback,
  initialSliderPos,
  sendSliderEvent,
}) => {
  const createIncrementsBetween = ({ from, to, increment }) => {
    let numbers = [];
    for (let i = from; i < to; i += increment) {
      numbers.push(i);
    }
    return numbers;
  };

  // Slider position must be in a range between 0 and 1
  if (initialSliderPos < 0 || initialSliderPos > 1) {
    initialSliderPos = 0.5;
  }

  const generatedNumbers = [];

  for (let i = 0; i < increments.length; i++) {
    generatedNumbers.push(...createIncrementsBetween(increments[i]));
  }

  const sliderRef = useRef(null);
  const { isSliding, value } = useSlider(sliderRef);
  const [pos, setPos] = useState(initialSliderPos);
  const [gaEventCount, setGaEventCount] = useState(0);

  const currentIndex = Math.round(pos * (generatedNumbers.length - 1));

  useEffect(() => {
    if (!value) {
      return;
    }
    setPos(value);
  }, [value]);

  useEffect(() => {
    setValueCallback(generatedNumbers[currentIndex]);
    if (pos !== initialSliderPos && gaEventCount < 1) {
      sendSliderEvent();
      setGaEventCount(gaEventCount + 1);
    }
  }, [pos]);

  const handleIncrease = () => {
    if (currentIndex < generatedNumbers.length - 1) {
      return setPos((currentIndex + 1) / (generatedNumbers.length - 1));
    }
    return;
  };

  const handleDecrease = () => {
    if (currentIndex > 0) {
      return setPos((currentIndex - 1) / (generatedNumbers.length - 1));
    }
    return;
  };

  return (
    <Flex width={1}>
      <TrackContainer>
        <TrackButton disabled={!!(pos <= 0)} onClick={handleDecrease}>
          -
        </TrackButton>
        <Track ref={sliderRef}>
          <Thumb
            position={pos}
            sliding={isSliding}
            value={
              numberOnThumb
                ? formatCurrency(generatedNumbers[currentIndex])
                : null
            }
          />
        </Track>
        <TrackButton disabled={!!(pos >= 1)} onClick={handleIncrease}>
          +
        </TrackButton>
      </TrackContainer>
    </Flex>
  );
};

Slider.propTypes = {
  increments: PropTypes.arrayOf(
    PropTypes.shape({
      from: PropTypes.number.isRequired,
      to: PropTypes.number.isRequired,
      increment: PropTypes.number.isRequired,
    })
  ),
  numberOnThumb: PropTypes.bool,
  setValueCallback: PropTypes.func,
  initialSliderPos: PropTypes.number,
  sendSliderEvent: PropTypes.func,
};

Slider.defaultProps = {
  numberOnThumb: false,
  initialSliderPos: 0.5,
  sendSliderEvent: () => {},
};

export default Slider;
