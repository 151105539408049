import styled from 'styled-components';
import { rem } from 'polished';

export const TrackContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const Track = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0 ${rem(20)};
  height: ${rem(40)};

  &:after {
    content: '';
    width: 100%;
    background-color: ${({ theme }) => theme.colors.helperText};
    position: absolute;
    top: 50%;
    left: 0;
    height: ${rem(1)};
    z-index: 1;
  }
`;

export const Thumb = styled.div`
  background-color: ${({ theme }) => theme.colors.accent};
  position: relative;
  left: ${({ position }) => `${Math.round(position * 100)}%`};
  transform: translateX(-50%);
  border-radius: 100%;
  width: ${rem(20)};
  height: ${rem(20)};
  cursor: ${({ sliding }) => (sliding ? `grabbing` : `grab`)};
  z-index: 2;

  &:before {
    transform: translateX(-45%) translateY(-125%);
    position: absolute;
    content: ${({ value }) => value && `'${value}'`};
    font-size: ${rem(16)};
    font-weight: 500;

    ${({ theme }) => theme.media.smallTablet} {
      font-size: ${rem(22)};
    }
  }
`;

export const TrackButton = styled.div`
  border-radius: 100%;
  width: ${rem(30)};
  display: flex;
  justify-content: center;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colors.backgroundDark : theme.colors.icon};
  font-weight: 700;
  color: ${({ theme }) => theme.colors.lightIcon};
  transition: all 0.1s ease-in-out;
  cursor: ${({ disabled }) => (disabled ? ` not-allowed` : `pointer`)};
  user-select: none;

  ${({ theme }) => theme.media.smallTablet} {
    font-size: ${rem(20)};
  }
`;
